import { parse } from 'cookie';

import { InvalidArgumentError } from '@/utils/errors';

import {
  CookieModel,
  CookieNotFoundError,
  ICookie,
  SimpleCookieModel
} from '@/services/models/Cookie';
import type { DTO } from '@/type-utils';
import { uniqueBy } from '@/utils/array-utils';
import { isNullOrEmpty } from '@/utils/null-utils';
import Service from '../../Service';
import type IMappable from '../../traits/IMappable';
import ConfigurationService from '../ConfigurationService';
import CurrentRequestService from '../CurrentRequestService';
import { EnvironmentService } from '../EnvironmentService';

import { Country, Language } from '../I18NService';
import LoggerService from '../LoggerService';
import { RunContextService } from '../RunContextService';

/**
 * Options for deleting a cookie. These are used to
 * specify exactly which cookie to delete.
 * Multiple cookies can exist with the same name, under a different path.
 */
interface ICookieDeleteOptions {
  /** Path, if any, that was used when the cookie was created. */
  path?: CookieModel['path'];

  /** Domain, if any, that was used when the cookie was created. */
  domain?: CookieModel['domain'];
}

/**
 * Provides a unified way of working on Cookies, both for the server and the client.
 *
 * For the server, will interact with the headers from the current request to get/set cookies.
 * For the client, will interact with client APIs (document.cookie).
 *
 * **Note**: Cookies with empty keys or values are ignored by this service.
 * This means that getting a cookie with an empty key or value will return `undefined`,
 * and setting a cookie with an empty key or value will have no effect. While it is still
 * possible to instantiate a `CookieModel` with an empty value, it is not recommended
 * to do so. Otherwise, those must be handled manually.
 */
class CookieService extends Service implements IMappable<CookieModel> {
  /**
   * A private method that constructs a simple map of cookies.
   * Both on client and the server, we can only get the name and the value of the cookie,
   * either from the Cookie header or the document.cookie. Therefore, a simple key-value map
   * represents all info that we have on cookies when retrieving them.
   *
   * Even though the general idea of this service is to work with the `CookieModel`,
   * the map is kept as a simple string map, to reduce footprint for the `has` and `get` operations,
   * which don't need ALL cookies reconstructed into CookieModels.
   *
   * **Note**: On the server, a deleted cookie is represented as a cookie with an empty value.
   * Therefore, the `getCookieJar` method may still return a cookie after it's been deleted.
   * However, cookies with empty keys or values are ignored by other `public` methods.
   *
   * @returns A mapping of cookie names and cookie values.
   */
  private getCookieJar(): Record<string, string> {
    // for server components, we can get the cookies from the run context
    if (RunContextService.isContextSupported) {
      const { request } = RunContextService.context;
      if (request.cookies) {
        return Object.fromEntries(request.cookies);
      }
      return {};
    }

    // Will either be a `Cookie` header or a `document.cookie` string.
    let cookieString;

    if ((typeof window === "undefined")) {
      const [req, res] = CurrentRequestService.get();

      const reqCookieHeader = req.headers?.get('cookie');
      const parsedReqCookies = this.parseCookieHeader(reqCookieHeader);

      const resSetCookieHeader = res.getHeader('Set-Cookie');
      const parsedResSetCookies = this.parseSetCookieHeader(resSetCookieHeader);

      // ensure the response cookies come after the request cookies
      // so that the last occurrence of a cookie is the one that is kept
      const cookieArr = this.dedupeCookies(
        ...parsedReqCookies,
        ...parsedResSetCookies
      );

      cookieString = cookieArr.join('; ');
    } else {
      cookieString = document.cookie ?? '';
    }

    return parse(cookieString);
  }

  /**
   * Extracts the name of the cookie from the cookie string.
   * @example getCookieName('hello=world') // 'hello'
   * @param cookieString - A string representing a cookie.
   * @returns The name of the cookie.
   */
  private getCookieName(cookieString: string): string {
    return cookieString.split('=')[0].trim();
  }

  /**
   * Filters out duplicate cookies (by name), keeping the last occurrence.
   * @param headers - An array of cookie strings.
   * @returns A new array of unique cookie strings.
   */
  private dedupeCookies(...headers: Array<string>): Array<string> {
    // reverse the headers to ensure the last occurrence is the first one considered
    const reversedResult = uniqueBy(headers.reverse(), (cookie) =>
      this.getCookieName(cookie)
    );

    // reverse the result to get the original order
    return reversedResult.reverse();
  }

  /**
   * Parses `Cookie` headers into an array of individual cookies.
   *
   * **Note**: Cookies with the same name are **not** deduplicated.
   *
   * @param cookieHeader - The value or list of values of `Cookie` headers.
   * @param delimiter - The delimiter to split the cookies by.
   * @returns An array of individual cookies.
   * @example
   * parseCookieHeader('hello=world; cat=dog') // ['hello=world', 'cat=dog']
   * parseCookieHeader(['hello=world; cat=dog', 'foo=bar']) // ['hello=world', 'cat=dog', 'foo=bar']
   */
  private parseCookieHeader(
    cookieHeader: string | Array<string> | undefined
  ): Array<string> {
    if (isNullOrEmpty(cookieHeader)) {
      return [];
    }

    /**
     * Each element of this array should resemble the value of a `Cookie` header.
     * @example
     * ['hello=world; cat=dog', 'foo=bar']
     */
    const cookieHeaderList =
      typeof cookieHeader === 'string' ? [cookieHeader] : cookieHeader;

    const result = [];
    for (const header of cookieHeaderList) {
      const cookies = header.split(',').map((str) => str.trim());
      result.push(...cookies);
    }

    return result;
  }

  /**
   * Parses `Set-Cookie` headers into an array of individual cookies.
   *
   * **Note**: Cookies with the same name are **not** deduplicated.
   *
   * @param setCookieHeader - The value or list of values of `Set-Cookie` headers.
   * @param delimiter - The delimiter to split the cookies by.
   * @returns An array of individual cookies.
   * @example
   * parseSetCookieHeader('hello=world; SameSite=None; Secure') // ['hello=world']
   * parseSetCookieHeader(['hello=world; SameSite=None; Secure', 'foo=bar; Max-Age=3600']) // ['hello=world', 'foo=bar']
   */
  private parseSetCookieHeader(
    setCookieHeader: string | Array<string> | undefined
  ): Array<string> {
    if (isNullOrEmpty(setCookieHeader)) {
      return [];
    }

    /**
     * Each element of this array should resemble the value of a `Set-Cookie` header.
     * @example
     * ['hello=world; SameSite=None; Secure', 'foo=bar; Max-Age=3600']
     */
    const setCookieHeaderList =
      typeof setCookieHeader === 'string' ? [setCookieHeader] : setCookieHeader;

    const result = [];
    for (const header of setCookieHeaderList) {
      // the cookie is always the first part of the Set-Cookie header
      const cookie = header.split(';')[0].trim();
      result.push(cookie);
    }

    return result;
  }

  /**
   * Validates the key of a cookie. The key must be a non-empty string.
   * @param key - The key of the cookie.
   * @returns `true` if the key is valid, `false` otherwise.
   */
  private isValidCookieKey(key: string): boolean {
    return typeof key === 'string' && key !== '';
  }

  /**
   * Validates the value of a cookie. The value must be a non-empty string.
   * @param value - The value of the cookie.
   * @returns `true` if the value is valid, `false` otherwise.
   */
  private isValidCookieValue(value: string): boolean {
    return typeof value === 'string' && value !== '';
  }

  /**
   * Sets a cookie.
   *
   * This method only implements the version of `IMappable.set` which accepts
   * a single value, since the `CookieModel` contains the `key` and the `value`,
   * having a `key` argument in set would be redundant.
   * **Note**: cookies with empty keys or values are ignored.
   *
   * @param cookie - The cookie to set.
   * @throws When a cookie value exceeds a limit set in the config.
   * @throws When an `httpOnly` cookie is set on the client side.
   * @example
   * CookieService.set(CookieModel.from({
   *  key: 'sessionId',
   *  value: '123456789',
   *  secure: true,
   *  path: '/',
   *  maxAge: 3600
   * }));
   *
   * // ICookie DTOs are also accepted
   * CookieService.set({
   *  key: 'sessionId',
   *  value: '123456789',
   *  secure: true,
   *  path: '/',
   *  maxAge: 3600
   * }).
   */
  public set(cookie: ICookie | DTO<ICookie>): void {
    const { key, value } = cookie;
    const isValidKey = this.isValidCookieKey(key);

    if (!isValidKey) {
      LoggerService.warn(`Tried setting a cookie with an invalid key: ${key}.`);
      return;
    }

    const isValidValue = this.isValidCookieValue(value);

    if (!isValidValue) {
      LoggerService.warn(
        `Tried setting a cookie with an invalid value: ${value}.`
      );
      return;
    }

    this._set(cookie);
  }

  /**
   * Sets a cookie.
   *
   * This `private` method skips the validation of the key and the value,
   * which allows us to set cookies with empty values to mark them as deleted.
   *
   * @param cookie - The cookie to set.
   * @throws When a cookie value exceeds a limit set in the config.
   * @throws When an `httpOnly` cookie is set on the client side.
   */
  private _set(cookie: ICookie | DTO<ICookie>): void {
    const cookieModel = CookieModel.from(cookie);
    const rawString = cookieModel.toRawString();
    const { length } = rawString;

    /** Warning threshold for cookies, in bytes. */
    const warnThreshold = ConfigurationService.getConfig(
      'cookies',
      'en' as Language.EN,
      'US' as Country.US
    ).getSetting('warnThreshold').value;

    /** Error threshold for cookies, in bytes. */
    const errorThreshold = ConfigurationService.getConfig(
      'cookies',
      'en' as Language.EN,
      'US' as Country.US
    ).getSetting('errorThreshold').value;

    if (length >= errorThreshold) {
      throw new InvalidArgumentError(
        `The cookie value is too long: ${length} bytes. Most browsers have a limit at ${errorThreshold} bytes (including name, value and the attributes of the cookie).`
      );
    }

    if (length >= warnThreshold) {
      LoggerService.warn(
        `Observed a cookie which is ${length} bytes long. Be careful!: ${rawString}`
      );
    }

    if (RunContextService.isContextSupported) {
      throw new Error('Cookies cannot be set within server components.');
    } else if ((typeof window === "undefined")) {
      const [_, res] = CurrentRequestService.get();
      res.appendHeader('Set-Cookie', rawString);
    } else {
      if (cookie.httpOnly) {
        throw new InvalidArgumentError(
          "`HttpOnly` cookies can't be set on the client side"
        );
      }

      document.cookie = rawString;
    }
  }

  /**
   * Tries to retrieve a cookie, or `undefined` if the cookie is not present.
   *
   * @param key - The name of the cookie to retrieve.
   * @returns The cookie, or `undefined` if cookie doesn't exist or is invalid.
   */
  public tryGet(key: string): SimpleCookieModel | undefined {
    if (!this.isValidCookieKey(key)) return undefined;

    const value = this.getCookieJar()[key];

    if (!this.isValidCookieValue(value)) return undefined;

    return CookieModel.from({
      key,
      value
    });
  }

  /**
   * Determines if a cookie exists.
   *
   * @param key - The name of the cookie.
   * @returns A boolean indicating wether the cookie exists.
   */
  public has(key: string): boolean {
    if (!this.isValidCookieKey(key)) return false;

    const cookieValue = this.getCookieJar()[key];
    const isValidCookie = this.isValidCookieValue(cookieValue);
    return isValidCookie;
  }

  /**
   * Retrieves a cookie.
   *
   * @param key - The name of the cookie.
   * @throws If the cookie doesn't exist.
   * @returns A value of the cookie.
   */
  public get(key: string): SimpleCookieModel {
    const cookie = this.tryGet(key);
    if (cookie === undefined) {
      throw new CookieNotFoundError(`Cookie "${key}" not found.`);
    }

    return cookie;
  }

  /**
   * Deletes a cookie given a name of the cookie.
   * Keep in mind that the `path` and the `domain` of the cookie are a
   * part of the identifier of the cookie, therefore if the cookie has been set with those
   * they should be present in order to delete it.
   *
   * @param key - Name of the cookie.
   * @param options - Cookie attributes to help further identify the correct cookie.
   * @returns A boolean indicating if the delete operation was successful.
   */
  public delete(key: string, options?: ICookieDeleteOptions): boolean {
    /**
     * It's technically possible to delete a cookie even if it doesn't exist in the current request.
     * For example, this request is under `/api` path and doesn't have access to a cookie under `/test`,
     * but it can still be deleted.
     * Therefore we can't really know if the cookie exists or if it can be deleted.
     * Therefore, this method can not reliably return `false` to indicate that
     * the cookie didn't exist and could not be deleted.
     */
    this._set({
      key,
      value: '',
      maxAge: -1,
      expires: new Date(1).toISOString(),
      path: options?.path ?? '/',
      domain: options?.domain
    });

    return true;
  }

  /**
   * Retrieves all cookies.
   * @returns An array of all cookies as `CookieModels`.
   */
  public getAll(): Array<CookieModel> {
    const jar = this.getCookieJar();

    // Convert a map into an array of [key, value]-s
    // and construct minimalistic CookieModels
    return Object.entries(jar).reduce<Array<CookieModel>>(
      (result, [key, value]) => {
        // we have to check if the key is undefined because it is
        // possible to set a cookie with an undefined key
        if (!this.isValidCookieKey(key) || !this.isValidCookieValue(value)) {
          return result;
        }

        result.push(
          CookieModel.from({
            key,
            value
          })
        );

        return result;
      },
      []
    );
  }
}

export default new CookieService();
