import axios, { AxiosError } from 'axios';

import {
  InvalidOperationError,
  InvalidStateError,
  NotImplementedError
} from '@/utils/errors';

import { DTO, Nullable } from '@/type-utils';
import { hasCause } from '@/utils/error-utils';
import { exhaustiveGuard } from '@/utils/function-utils';
import { decodeJWTPayload } from '@/utils/token-utils';
import { USER_ID_KEY } from '@/configs/env/public';
import Service from '../../Service';
import ServerUserService from '../../serverless/ServerUserService';
import AccountService from '../AccountService';
import { EnvironmentService } from '../EnvironmentService';
import LoggerService from '../LoggerService';
import SessionService from '../SessionService';
import CognitoService, {
  ICognitoIDTokenPayload
} from '../integrations/CognitoService';

import type { IAccount } from '../../models/Account';
import { IUser, UserModel, UserType } from '../../models/User';
import {
  AnonymousUserModel,
  IAnonymousUser
} from '../../models/User/AnonymousUser';
import {
  AuthenticatedUserModel,
  IAuthenticatedUser,
  IAuthenticatedUserTokens,
  ILoginCredentials,
  ISignUpCredentials
} from '../../models/User/AuthenticatedUser';
import { FraudAccountEventType } from '../../serverless/ServerFraudService/schemas';
import FraudService from '../FraudService';
import UserServiceMock from './UserServiceMock';
import { UnableToCreateUserAccountError } from './errors/UnableToCreateUserAccountError';
import { UserAccountAlreadyExistsError } from './errors/UserAccountAlreadyExistsError';
import CookieService from '../CookieService';

/** Abstracts operations related to User Authentication. */
export class UserService extends Service {
  private client = axios.create({ baseURL: '/api/user' });

  /**
   * Checks if the current user is logged in.
   * @returns `true` if there is currently a logged in user.
   */
  public async isLoggedIn(): Promise<boolean> {
    const currentUser = await this.getCurrentUser().catch(() => null);

    // There is no logged-in user if either there's no current user, or if
    // there is one of `Anonymous` type.
    const isLoggedIn = !!currentUser && currentUser.type !== UserType.Anonymous;

    return isLoggedIn;
  }

  /**
   * Tries to retrieve the User ID stored in the current request's cookies.
   *
   * Will return `null` if either the User ID is missing, or if the current
   * environment does not support cookies.
   *
   * @returns The User ID as a string (if found). `null` otherwise.
   */
  public tryGetCurrentUserID(): Nullable<string> {
    return CookieService.tryGet(USER_ID_KEY)?.value ?? null;
  }

  /**
   * Returns a {@link UserModel} representing the user stored in the current session.
   * @param [update=false] - Whether to update the user data from the server. When logged in
   * the account data will be updated from the server and added to the session.
   * @returns A {@link UserModel} representing the current user.
   *
   * @todo Cache on the client. Invalidate when the user logs in and out.
   */
  public async getCurrentUser(update = false): Promise<UserModel> {
    let dtoUser: DTO<IUser>;

    if ((typeof window === "undefined")) {
      dtoUser = update
        ? await ServerUserService.getUpdatedAccount()
        : await ServerUserService.getCurrentUser();
    } else {
      const result = await this.client.get<DTO<IUser>>('', {
        params: { update }
      });
      dtoUser = result.data;
    }

    switch (dtoUser.type) {
      case UserType.Anonymous:
        return AnonymousUserModel.from(dtoUser as DTO<IAnonymousUser>);

      case UserType.Authenticated:
        return AuthenticatedUserModel.from(dtoUser as DTO<IAuthenticatedUser>);
      case UserType.CallCenterRepresentative:
      case UserType.RetailAssociate:
        throw new NotImplementedError(
          `User of type "${dtoUser.type}" is currently not supported.`
        );
    }

    return exhaustiveGuard(
      dtoUser.type,
      new InvalidStateError(
        `Retrieved a user DTO from session with an unknown type "${dtoUser.type}".`
      )
    );
  }

  /**
   * Stores the supplied user in {@link SessionService Session}.
   *
   * @param user - User to store in session. Must be a {@link UserModel}.
   * @returns A promise that resolves on success.
   */
  public async saveUserToSession(user: UserModel): Promise<void> {
    if ((typeof window === "undefined")) {
      await ServerUserService.saveCurrentUser(user.toDTO());
      return;
    }

    await this.client.put('', user.toDTO());
  }

  /**
   * Resets the current user to a new anonymous user.
   * This will update the session data and the user ID cookie.
   */
  public async resetUser(): Promise<void> {
    if ((typeof window === "undefined")) {
      await ServerUserService.resetUser();
      return;
    }

    await this.client.delete('');
  }

  /**
   * Registers a new {@link IAuthenticatedUser authenticated user} and automatically logs in.
   * This does **NOT** automatically merge the user's cart with their existing cart.
   *
   * @param credentials - The new user's credentials.
   *
   * @throws An {@link InvalidOperationError} if this method is called while a user is already logged in.
   * @throws An {@link UserAccountAlreadyExistsError} if the user account already exists and
   * therefor could not be created.
   * @throws An {@link UnableToCreateUserAccountError} if the user account could not be
   * created for any other reason.
   *
   * @returns A {@link UserModel} on success, which will include the user's {@link IAccount account} and a set
   * of {@link IAuthenticatedUserTokens tokens} to authenticate requests.
   */
  public async signUp(
    credentials: ISignUpCredentials
  ): Promise<AuthenticatedUserModel> {
    // First, determine if there is a user currently logged in.
    let currentUser: Nullable<UserModel>;

    try {
      currentUser = await this.getCurrentUser();
    } catch (e) {
      throw new InvalidStateError(
        'Sign Up failed. Could not determine if the session is already logged in as another user.',
        { cause: e as Error }
      );
    }

    // If there is an existing user, and it is not of `Anonymous` type.
    if (currentUser && currentUser.type !== UserType.Anonymous) {
      throw new InvalidOperationError(
        `Already logged in. Please log out first to sign up as another user.`
      );
    }

    try {
      // NEXT api endpoint to create an account.
      const account = (
        await this.client.post<IAccount>('/create-account', credentials)
      ).data;

      // In this particular case, the UserService.logIn method is not used
      // because the user was just created; and since Account creation in AWS
      // is asynchronous, it is possible that the account data is not yet saved
      // to AWS when logging in right after signing up.

      // Authenticate the user directly with Cognito...
      const tokens = await CognitoService.logIn(credentials);
      const { idToken } = tokens;
      const { email } = credentials;

      const { 'cognito:username': userID } =
        decodeJWTPayload<ICognitoIDTokenPayload>(idToken);

      // Create an AuthenticatedUserModel...
      const userModel = new AuthenticatedUserModel({
        type: UserType.Authenticated,
        uuid: userID,
        email,
        tokens,
        account
      });

      // Save the model to session...
      await this.saveUserToSession(userModel);

      // Once the user signs up and all other steps completed
      // successfully, send a sign up event via the FraudService.
      // Note the result is not used, so the call does not need to be awaited.
      FraudService.sendAccountEvent({
        eventType: FraudAccountEventType.Signup,
        account
      });

      // And finally, return it.
      return userModel;
    } catch (e) {
      if ((e as AxiosError).isAxiosError) {
        const err = e as AxiosError;
        if (hasCause(err.response?.data, UserAccountAlreadyExistsError.name)) {
          // If the user already exists, throw a more specific error. Please do not ever
          // change this message to include the email address as this would put PII in our
          // logs. Furthermore, make sure this error is never modified to provide any more
          // specific information than the `UnableToCreateUserAccountError` in production
          // environments. This is a security measure to prevent email enumeration attacks.
          throw new UserAccountAlreadyExistsError(
            'Cannot create the user account for the specified email. ' +
              'The email address is already in use.',
            { cause: e as Error }
          );
        }

        throw new UnableToCreateUserAccountError(
          'An unexpected error prevented the user account from being created.',
          { cause: e as Error }
        );
      }

      throw e;
    }
  }

  /**
   * Logs a {@link IUser user} in using the supplied {@link ILoginCredentials credentials}.
   * This does **NOT** automatically merge the user's cart with their existing cart.
   *
   * @param credentials - The credentials to use.
   *
   * @throws An {@link InvalidOperationError} if this method is called while a user is already logged in.
   *
   * @returns A {@link UserModel} on success, which will include the user's {@link IAccount account} and a set
   * of {@link IAuthenticatedUserTokens tokens} to authenticate requests.
   */
  public async logIn(
    credentials: ILoginCredentials
  ): Promise<AuthenticatedUserModel> {
    // First, determine if there is a user currently logged in.
    let currentUser: Nullable<UserModel>;

    try {
      currentUser = await this.getCurrentUser();
    } catch (e) {
      throw new InvalidStateError(
        'Failed to log in. Could not determine if the session is already logged in as another user.',
        { cause: e as Error }
      );
    }

    // If there is an existing user, and it is not of `Anonymous` type.
    if (currentUser && currentUser.type !== UserType.Anonymous) {
      throw new InvalidOperationError(
        `Already logged in. Please log out to log in as another user`
      );
    }

    // No user logged in, or the logged in user is of `Anonymous` type, continue the login process.

    try {
      // Get the tokens from Cognito by logging in with the credentials.
      const tokens = await CognitoService.logIn(credentials);
      const { accessToken, idToken, accountID } = tokens;
      const { email } = credentials;

      const { 'cognito:username': userID } =
        decodeJWTPayload<ICognitoIDTokenPayload>(idToken);

      // Get the user's account. Use the tokens for authentication.
      const account = await AccountService.getUserAccount(
        accountID,
        accessToken
      );

      const userModel = new AuthenticatedUserModel({
        type: UserType.Authenticated,
        uuid: userID,
        email,
        tokens,
        account
      });

      // Save this new user to session before returning it.
      await this.saveUserToSession(userModel);

      // Once the user is logged in and all other steps completed
      // successfully, send a login event via the FraudService to
      // mitigate a fraud miscall.
      // Note the result is not used, so the call does not need to be awaited.

      FraudService.sendAccountEvent({
        account,
        eventType: FraudAccountEventType.Login
      });

      // TODO: Cache User Models
      return userModel;
    } catch (err) {
      // If the login attempt fails for some reason and all other
      // steps send a login failure event via the FraudService.
      // Note the result is not used, so the call does not need to be awaited.
      FraudService.sendAccountEvent({
        /**
         * @todo Once we can query partial account
         * info by email alone, send info to Forter.
         *
         * **Note:** Fraud account events get validated by the
         * {@link FraudAccountEventSchema}. If the above TODO gets
         * addressed, make sure to update the schema to reflect
         * the way `account` should be sent.
         */
        account: undefined as never,
        eventType: FraudAccountEventType.LoginFailure
      });

      throw err;
    }
  }

  /**
   * Given a user's email address and a valid refresh token, get a new set of tokens
   * by refreshing the authentication session.
   *
   * @param email - The user's email address.
   * @param refreshToken - A valid refresh token.
   *
   * @returns A new set of {@link IAuthenticatedUserTokens tokens} on success.
   */
  public async refreshTokens(
    email: string,
    refreshToken: string
  ): Promise<IAuthenticatedUserTokens> {
    const result = await CognitoService.refreshTokens(email, refreshToken);

    const user = await this.getCurrentUser();
    if (this.isAuthenticated(user)) {
      // Once the user refreshes their session and all other steps
      // completed successfully, send a refresh event via the FraudService.
      // Note the result is not used, so the call does not need to be awaited.
      FraudService.sendAccountEvent({
        account: user.account,
        eventType: FraudAccountEventType.Refresh
      });
    }

    return result;
  }

  /**
   * Given an authenticated user, refresh the user's tokens and save the updated
   * user to session.
   * @param user - The authenticated user to refresh.
   * @returns The updated authenticated user.
   */
  public async refreshUserAuthorization(
    user: IAuthenticatedUser
  ): Promise<IAuthenticatedUser> {
    const { email, tokens } = user;

    const refreshedTokens = await this.refreshTokens(
      email,
      tokens.refreshToken
    );

    const refreshedUser = AuthenticatedUserModel.from({
      ...user,
      tokens: refreshedTokens
    });

    await this.saveUserToSession(refreshedUser);

    return refreshedUser;
  }

  /**
   * Confirms a user's email address with a confirmation code.
   * @param email - The user's email address.
   * @param confirmationCode - The received confirmation code.
   */
  public async confirmUser(
    email: string,
    confirmationCode: string
  ): Promise<void> {
    await CognitoService.confirmRegistration(email, confirmationCode);

    const user = await this.getCurrentUser();
    if (this.isAuthenticated(user)) {
      // Once the user confirms their email and all other steps completed
      // successfully, send a email confirmation event via the FraudService.
      // Note the result is not used, so the call does not need to be awaited.
      FraudService.sendAccountEvent({
        account: user.account,
        eventType: FraudAccountEventType.EmailConfirmation
      });
    }
  }

  /**
   * Resends a confirmation code to a user.
   * @param email - The user's email address.
   */
  public async resendConfirmationCode(email: string): Promise<void> {
    await CognitoService.resendConfirmationCode(email);

    const user = await this.getCurrentUser();
    if (this.isAuthenticated(user)) {
      // Once the user requests a password reset and all other steps completed
      // successfully, send a password reset request event via the FraudService.
      // Note the result is not used, so the call does not need to be awaited.
      FraudService.sendAccountEvent({
        account: user.account,
        eventType: FraudAccountEventType.PasswordResetRequest
      });
    }
  }

  /**
   * Initiates a password reset flow and sends a verification code to the
   * user's email address.
   *
   * This method must be called to generate the verification code `resetPassword`
   * requires.
   *
   * @param email - The user's email address.
   */
  public async requestPasswordResetCode(email: string): Promise<void> {
    await CognitoService.requestPasswordResetCode(email);

    const user = await this.getCurrentUser();
    if (this.isAuthenticated(user)) {
      // Once the user requests a password reset and all other steps completed
      // successfully, send a password reset request event via the FraudService.
      // Note the result is not used, so the call does not need to be awaited.
      FraudService.sendAccountEvent({
        account: user.account,
        eventType: FraudAccountEventType.PasswordResetRequest
      });
    }
  }

  /**
   * Resets a user's password with a verification code. Verification codes can
   * be generated by calling `requestPasswordResetCode`.
   *
   * @param email - The user's email address.
   * @param verificationCode - The verification code sent to the user's email address.
   * @param newPassword - The new password.
   */
  public async resetPassword(
    email: string,
    verificationCode: string,
    newPassword: string
  ): Promise<void> {
    await CognitoService.resetPassword(email, verificationCode, newPassword);

    const user = await this.getCurrentUser();
    if (this.isAuthenticated(user)) {
      // Once the user resets their password and all other steps completed
      // successfully, send a password reset event via the FraudService.
      // Note the result is not used, so the call does not need to be awaited.
      FraudService.sendAccountEvent({
        account: user.account,
        eventType: FraudAccountEventType.PasswordReset
      });
    }
  }

  /**
   * Signs a user out and invalidates the active session.
   */
  public async signOut(): Promise<void> {
    try {
      const currentUser = await this.getCurrentUser();

      if (!currentUser || currentUser.type === UserType.Anonymous) {
        throw new InvalidOperationError(
          `Could not sign out; there is no user currently logged in.`
        );
      }

      const isUserAuthenticated = this.isAuthenticated(currentUser);

      if (!isUserAuthenticated) {
        throw new InvalidOperationError(
          `Could not sign out; the currently logged in user does not support logging out.`
        );
      }

      const { email, account } = currentUser;

      await CognitoService.signOut(email);
      await this.resetUser();

      // Once the user is logs out and all other steps completed
      // successfully, send a logout event via the FraudService.
      // Note the result is not used, so the call does not need to be awaited.

      FraudService.sendAccountEvent({
        account,
        eventType: FraudAccountEventType.Logout
      });
    } catch (error) {
      // This can happen if the user has logged out in another tab.
      if (error instanceof InvalidOperationError) {
        LoggerService.warn(error);
      } else {
        throw error;
      }
    }
  }

  /**
   * A utility type guard for checking whether the user is authenticated.
   * @param user - The user model to check.
   * @returns A boolean indicating whether the user is a AuthenticatedUserModel.
   */
  public isAuthenticated(user: IUser): user is IAuthenticatedUser {
    return (
      user.type === UserType.Authenticated &&
      'email' in user &&
      'tokens' in user &&
      'account' in user
    );
  }
}

export default UserService.withMock(
  new UserServiceMock(UserService)
) as unknown as UserService;
