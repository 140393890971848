import { InvalidArgumentError } from '@/utils/errors';

import { DTO } from '@/type-utils';

import type { IOrderLine } from '@/services/models/Order';
import { exhaustiveGuard } from '@/utils/function-utils';
import axios from 'axios';
import { isNullish } from '@/utils/null-utils';
import {
  RejectedCouponError,
  CouponRejectionReason
} from '@/services/models/Cart/Coupon';
import Service from '../../Service';
import type { ICart } from '../../models/Cart';
import type { ILineItem } from '../../models/Cart/LineItem';
import {
  DiscountMode,
  ICartPromotionUpdates
} from '../../models/Cart/Promotion';
import type { ILineItemDiscount } from '../../models/Cart/Promotion/LineItem';
import { IMoney, MoneyModel } from '../../models/Money';

import PromotionsServiceMock from './PromotionsServiceMock';
import ServerPromotionsService from '../../serverless/ServerPromotionsService';
import { EnvironmentService } from '../EnvironmentService';
import { msg } from '../I18NService';
import { cart_couponCodes_rejection_expired } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_expired";
import { cart_couponCodes_rejection_limitReached } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_limitReached";
import { cart_couponCodes_rejection_notFound } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_notFound";
import { cart_couponCodes_rejection_notActive } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_notActive";
import { cart_couponCodes_rejection_notApplicable } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_notApplicable";
import { cart_couponCodes_rejection_alreadyApplied } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_alreadyApplied";

/** Abstracts operations related to Promotions. */
export class PromotionsService extends Service {
  private client = axios.create({
    baseURL: '/api/promotions'
  });

  /**
   * Returns the localized message that describes the rejection reason for a
   * coupon.
   *
   * @param rejectionReason - The reason this coupon is being rejected.
   *
   * @returns The localized message that describes this error.
   * @throws An {@link InvalidArgumentError} if the rejection reason of this
   * error is invalid.
   */
  public getRejectionReasonMessage(
    rejectionReason: CouponRejectionReason
  ): string {
    switch (rejectionReason) {
      case CouponRejectionReason.Expired:
        return msg(cart_couponCodes_rejection_expired);
      case CouponRejectionReason.LimitReached:
        return msg(cart_couponCodes_rejection_limitReached);
      case CouponRejectionReason.NotFound:
        return msg(cart_couponCodes_rejection_notFound);
      case CouponRejectionReason.NotActive:
        return msg(cart_couponCodes_rejection_notActive);
      case CouponRejectionReason.NotApplicable:
        return msg(cart_couponCodes_rejection_notApplicable);
      case CouponRejectionReason.AlreadyApplied:
        return msg(cart_couponCodes_rejection_alreadyApplied);
      default:
        throw new InvalidArgumentError(
          `Cannot get coupon rejection intl message: Unknown rejection reason "${rejectionReason}" specified.`
        );
    }
  }

  /**
   * Given a discount mode (present in discount-type promotions), calculate
   * the discount amount to apply.
   *
   * @param originalAmount - The original amount that the discount is being applied to.
   * @param discountValue - The discount value.
   * @param discountMode - The discount mode.
   *
   * @returns The calculated discount amount (number).
   *
   * @throws An {@link InvalidArgumentError} whenever the discount value or mode are invalid.
   */
  public getDiscountAmount(
    originalAmount: IMoney,
    discountValue: number,
    discountMode: DiscountMode
  ): IMoney {
    switch (discountMode) {
      case DiscountMode.Fixed: {
        // Discount value is a fixed amount.
        return MoneyModel.fromAmount(discountValue);
      }

      case DiscountMode.Percentage: {
        // Discount value is a percentage of the original amount.

        if (discountValue < 0 || discountValue > 100) {
          throw new InvalidArgumentError(
            `Cannot get amount after discount: Percentage type discounts must have a value between 0 and 100. Received: ${discountValue}`
          );
        }

        const moneyModel = MoneyModel.fromAmount(originalAmount);
        const discountAmount = moneyModel
          .multiplyBy(discountValue)
          .divideBy(100);

        return discountAmount;
      }

      case DiscountMode.Override: {
        // Discount value will take the place of the original amount.
        const moneyModel = MoneyModel.fromAmount(originalAmount);
        const discountAmount = moneyModel.subtractAmount(discountValue);
        return discountAmount;
      }
    }

    return exhaustiveGuard(
      discountMode,
      `Cannot get amount after discount: Invalid mode "${discountMode}" was supplied.`
    );
  }

  /**
   * Calculates the discount amount for a line item.
   *
   * @param lineItem - The line item to calculate the discount for.
   * @param discount - The discount to apply.
   *
   * @returns The discount amount.
   */
  public getLineItemDiscountAmount(
    lineItem: Pick<ILineItem, 'quantity' | 'subtotal' | 'unitPrice'>,
    discount: ILineItemDiscount
  ): IMoney {
    const { quantity, subtotal, unitPrice } = lineItem;
    const { apply, value, mode } = discount;

    // If this discount will be applied per unit...
    if (apply === 'forEach') {
      const initialAmount =
        // ...use the current price if available...
        unitPrice.currentPrice ??
        // ... and fall back to the retail price if current price is not present.
        unitPrice.retailPrice;

      const discountAmount = this.getDiscountAmount(initialAmount, value, mode);

      // Multiply the discount amount by the quantity of the line item.
      return MoneyModel.from(discountAmount).multiplyBy(quantity);
    }

    // If this discount is to be applied to the whole amount, just use the subtotal.
    const initialAmount = subtotal;

    return this.getDiscountAmount(initialAmount, value, mode);
  }

  /**
   * Calculates the discount amount for an {@link IOrderLine order line}.
   *
   * @param orderLine - The line to calculate the discount for.
   * @param discount - The discount to apply.
   *
   * @returns The discount amount.
   */
  public getOrderLineDiscountAmount(
    orderLine: Pick<IOrderLine, 'quantity' | 'netTotal' | 'unitPrice'>,
    discount: ILineItemDiscount
  ): IMoney {
    const { quantity, netTotal, unitPrice } = orderLine;
    const { apply, value, mode } = discount;

    // If this discount will be applied per unit...
    if (apply === 'forEach') {
      const discountAmount = this.getDiscountAmount(unitPrice, value, mode);

      // Multiply the discount amount by the quantity of the line item.
      return MoneyModel.from(discountAmount).multiplyBy(quantity);
    }

    // If this discount is to be applied to the whole amount, just use the subtotal.
    const initialAmount = netTotal;

    return this.getDiscountAmount(initialAmount, value, mode);
  }

  /**
   * Given a Cart, retrieve its applicable promotions.
   *
   * @param cart - The cart to retrieve the promotions for.
   *
   * @returns A promise that will resolve with the updates
   * (promotions and coupon) to apply to the supplied cart.
   */
  public async getCartPromotions(
    cart: DTO<ICart>
  ): Promise<ICartPromotionUpdates> {
    if ((typeof window === "undefined")) {
      return ServerPromotionsService.getCartPromotions(cart);
    }

    const res = await this.client.post<ICartPromotionUpdates>(
      'get-for-cart',
      cart
    );
    return res.data;
  }

  /**
   * Apply the supplied coupon code to the given cart.
   *
   * **Note:** This method will return the updates to apply to the cart. Make
   * sure to apply them to the model afterwards (if using one).
   *
   * @param cart - The {@link} cart to apply the coupon to.
   * @param couponCode - The coupon code to apply.
   *
   * @throws A {@link RejectedCouponError} if the coupon is rejected.
   *
   * @returns A promise that will resolve with the updates to apply to the
   * supplied cart model.
   */
  public async applyCoupon(
    cart: DTO<ICart>,
    couponCode: string
  ): Promise<ICartPromotionUpdates> {
    if ((typeof window === "undefined")) {
      return ServerPromotionsService.applyCoupon(cart, couponCode);
    }

    try {
      const res = await this.client.post<ICartPromotionUpdates>(
        'apply-coupon',
        {
          cart,
          couponCode
        }
      );

      return res.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // If the error's code is 409 (Conflict), it means that the coupon was
        // rejected. Wrap it in `RejectedCouponError` to indicate this to the
        // UI so appropriate messaging is displayed.
        if (error.code === '409') {
          const rejectionReason = error.response?.data?.rejectionReason as
            | CouponRejectionReason
            | undefined;

          if (!isNullish(rejectionReason)) {
            throw new RejectedCouponError(couponCode, rejectionReason);
          }
        }
      }

      throw error;
    }
  }

  /**
   * Remove the specified coupon code from a given cart.
   *
   * **Note:** This method will return the updates to apply to the cart. Make
   * sure to apply them to the model afterwards (if using one).
   *
   * @param cart - The {@link} cart to remove the coupon from.
   * @param couponCode - The coupon code to remove.
   *
   * @returns A promise that will resolve with the updates to apply to the
   * supplied cart model.
   */
  public async removeCoupon(
    cart: DTO<ICart>,
    couponCode: string
  ): Promise<ICartPromotionUpdates> {
    if ((typeof window === "undefined")) {
      return ServerPromotionsService.applyCoupon(cart, couponCode);
    }

    const res = await this.client.post<ICartPromotionUpdates>('remove-coupon', {
      cart,
      couponCode
    });

    return res.data;
  }

  /**
   * Given a set of {@link ICartPromotionUpdates Cart Promotion Updates}, apply them to the specified DTO.
   * This will also include coupons and the promotions of the cart's line items.
   *
   * @param updates - Promotion updates to apply.
   * @param dto - {@link ICart Cart DTO} To apply the updates to.
   *
   * @returns An updated cart DTO with the updates applied.
   */
  public applyPromotionUpdatesToCartDTO(
    updates: ICartPromotionUpdates,
    dto: DTO<ICart>
  ): DTO<ICart> {
    const { coupons, cartPromotions, lineItemPromotions } = updates;

    // The new items array will be...
    const newItems = !isNullish(lineItemPromotions)
      ? // If promotions are present, a map of...
        dto.items.map((lineItem) => {
          // Each item with their promotions array overwritten.
          const newItemPromotions = lineItemPromotions[lineItem.sku] ?? []; // If no promotions were included for this item, include an empty array.

          return {
            ...lineItem,
            promotions: newItemPromotions
          };
        })
      : // If no promotions are present, just the dto's items again/
        dto.items;

    // Rebuild the DTO with...
    return {
      // All the previous data,
      ...dto,

      // The retrieved coupons, updated items, and cart promotions
      coupons,
      items: newItems,
      promotions: cartPromotions
    } as unknown as DTO<ICart>;
  }
}

export default PromotionsService.withMock(
  new PromotionsServiceMock(PromotionsService)
) as unknown as PromotionsService;
