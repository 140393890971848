import axios, { AxiosInstance, AxiosResponse } from 'axios';

import type { DTO, Nullable } from '@/type-utils';

import type { IGiftCardBase } from '@/services/models/GiftCard';
import ServerCartService, {
  CannotMergeCartsError
} from '@/services/serverless/ServerCartService';
import type { IInitialProduct } from '@/services/serverless/integrations/AWS/AWSCartService';
import Service from '../../Service';
import { EnvironmentService } from '../EnvironmentService';

import { CartModel, ICart } from '../../models/Cart';
import CartServiceMock from './CartServiceMock';

/**
 * In order to update the model on the client side we need the new item id from the
 * cart service.
 */
export type AddResponse = AxiosResponse<{
  /** The id coming back from the add service. */
  id: string;
}>;

/**
 * Isomorphic service that abstracts Cart-related logic.
 * @see {@link ServerCartService}, the server-only counterpart of this service with elevated privileges.
 */
export class CartService extends Service {
  /**
   * The current client.
   * @returns An Axios client.
   */
  private client: AxiosInstance = axios.create({ baseURL: '/api/cart' });

  /**
   * Gets a fresh cart for the user, and sets it
   * as the their main cart in the session.
   *
   * Note: this method does not automatically call {@link CartModel.revalidate}.
   *
   * @returns A new empty cart.
   */
  public async getNewCart(): Promise<CartModel> {
    if ((typeof window === "undefined")) {
      const dto = await ServerCartService.getNewCart();
      return CartModel.from(dto);
    }

    const { data } = await this.client.get<DTO<ICart>>('', {
      params: {
        isNewCart: true
      }
    });

    return CartModel.from(data);
  }

  /**
   * Gets a new ad-hoc cart that is not set as the user's main cart.
   * As a result, unlike {@link CartService.getNewCart}, this method
   * does not update the user's session.
   *
   * Note: this method does not automatically call {@link CartModel.revalidate}.
   *
   * @returns A new ad-hoc cart model.
   */
  public async getNewAdHocCart(): Promise<CartModel> {
    if ((typeof window === "undefined")) {
      const dto = await ServerCartService.getNewAdHocCart();
      return CartModel.from(dto);
    }

    const { data } = await this.client.get<DTO<ICart>>('', {
      params: {
        isNewCart: true,
        isAdHoc: true
      }
    });

    return CartModel.from(data);
  }

  /**
   * Gets a {@link CartModel} for a specified cart. If unspecified, it will try
   * to get the cart from the user's session. If the session does not have a cart,
   * a new one will be created and set as the user's cart.
   *
   * Note: this method does not automatically call {@link CartModel.revalidate}.
   *
   * @param [cartID] - The UUID of the cart to get a model for.
   * @returns A {@link CartModel} representation of the current user's cart.
   */
  public async getCart(cartID?: string): Promise<CartModel> {
    if ((typeof window === "undefined")) {
      const dto = await ServerCartService.getCart({ cartID });
      return CartModel.from(dto);
    }

    const { data } = await this.client.get<DTO<ICart>>('', {
      params: {
        cartID
      }
    });

    return CartModel.from(data);
  }

  /**
   * Gets the corresponding {@link CartModel} for the user's session.
   * If the session does not have a cart, `null` is returned.
   * @returns A {@link CartModel} of the cart in the user's session, or `null` if there is none.
   */
  public async getCartFromSession(): Promise<Nullable<CartModel>> {
    if ((typeof window === "undefined")) {
      const dto = await ServerCartService.tryGetCartFromSession();
      if (!dto) return null;
      return CartModel.from(dto);
    }

    const { data } = await this.client.get<Nullable<DTO<ICart>>>('session');

    if (!data) {
      return null;
    }

    return CartModel.from(data);
  }

  /**
   * Merges a guest cart with a signed-in user cart.
   * @param sourceCartID - The guest cart id.
   * @param destinationCartID - The user cart id.
   * @returns The new cart model.
   * @throws A {@link CannotMergeCartsError} if the cart IDs are the same.
   * @throws Some error if the carts could not be merged by the API.
   */
  public async mergeCarts(
    sourceCartID: string,
    destinationCartID: string
  ): Promise<CartModel> {
    if (sourceCartID === destinationCartID) {
      throw new CannotMergeCartsError(
        `Cannot merge cart ID ${sourceCartID} into itself. Source and destination cart IDs must be different.`
      );
    }

    if ((typeof window === "undefined")) {
      const cart = await ServerCartService.mergeCarts(
        sourceCartID,
        destinationCartID
      );
      return CartModel.from(cart);
    }

    const { data } = await this.client.post<DTO<ICart>>('merge', {
      sourceCartID,
      destinationCartID
    });

    return CartModel.from(data);
  }

  /**
   * Adds an item to the cart. It uses the line item or the product.
   * @param item - The line item or the product itself.
   * @param [cartID] - The cart id, if not supplied a new one will be generated.
   * @returns Returns the new line item uuid from the cart API.
   */
  public async addItem(
    item: IInitialProduct,
    cartID?: string
  ): Promise<string> {
    if ((typeof window === "undefined")) {
      const id = await ServerCartService.addItem(item, cartID);
      return id;
    }
    const { data } = await this.client.post<unknown, AddResponse>('item', {
      cartID: cartID ?? undefined,
      item
    });

    return data.id;
  }

  /**
   * Removes an item by its item id.
   * @param itemID - The line item uuid that comes from the cart.
   * @param [cartID] - The cart id, if not supplied a new one will be generated.
   */
  public async removeItem(itemID: string, cartID?: string): Promise<void> {
    if ((typeof window === "undefined")) {
      await ServerCartService.removeItem(itemID, cartID);
    } else {
      await this.client.delete('item', {
        params: {
          cartID: cartID ?? undefined,
          itemID
        }
      });
    }
  }

  /**
   * Updates the quantity of a line item in a cart.
   *
   * @param itemID - The line item uuid used to identify the item in the cart.
   * @param quantity - The new quantity.
   * @param [cartID] - The uuid used for the cart.
   */
  public async updateItem(
    itemID: string,
    quantity: number,
    cartID?: string
  ): Promise<void> {
    if ((typeof window === "undefined")) {
      await ServerCartService.updateItem(itemID, quantity, cartID);
    } else {
      await this.client.patch('item', {
        itemID,
        quantity,
        cartID: cartID ?? undefined
      });
    }
  }

  /**
   * Replaces a line item in a cart with a new item.
   *
   * @param itemID - The line item uuid used to identify the item in the cart.
   * @param newItem - The new item to replace the old one.
   * @param [cartID] - The uuid used for the cart.
   *
   * @returns The new line item uuid from the cart API.
   */
  public async replaceItem(
    itemID: string,
    newItem: IInitialProduct,
    cartID?: string
  ): Promise<string> {
    if ((typeof window === "undefined")) {
      return ServerCartService.replaceItem(itemID, newItem, cartID);
    }

    const { data } = await this.client.put<unknown, AddResponse>('item', {
      itemID,
      newItem,
      cartID
    });

    return data.id;
  }

  /**
   * Adds a coupon by its id.
   * @param couponCode - The coupon code.
   * @param [cartID] - The cart id, if not supplied a new one will be generated.
   * @returns The new coupon id.
   */
  public async addCoupon(couponCode: string, cartID?: string): Promise<string> {
    if ((typeof window === "undefined")) {
      return ServerCartService.addCoupon(couponCode, cartID);
    }

    const { data } = await this.client.post<unknown, AddResponse>('coupon', {
      cartID: cartID ?? undefined,
      couponCode
    });

    return data.id;
  }

  /**
   * Removes the coupon by its id.
   * @param couponID - The coupon id supplied by the cart api.
   * @param [cartID] - The cart id, if not supplied a new one will be generated.
   */
  public async removeCoupon(couponID: string, cartID?: string): Promise<void> {
    if ((typeof window === "undefined")) {
      await ServerCartService.removeCoupon(couponID, cartID);
    } else {
      await this.client.delete('coupon', {
        params: {
          cartID: cartID ?? undefined,
          couponID
        }
      });
    }
  }

  /**
   * Adds the gift card by its card info.
   * @param card - The gift card to be added.
   * @param [cartID] - The cart id, if not supplied a new one will be generated.
   * @returns - The new id for the gift card.
   */
  public async addGiftCard(
    card: IGiftCardBase,
    cartID?: string
  ): Promise<string> {
    if ((typeof window === "undefined")) {
      return ServerCartService.addGiftCard(card, cartID);
    }

    const { data } = await this.client.post<unknown, AddResponse>('giftcard', {
      cartID: cartID ?? undefined,
      giftCard: card
    });

    return data.id;
  }

  /**
   * Removes the gift card by the id supplied by the cart api.
   * @param cardID - The gift card id.
   * @param [cartID] - The cart id, if not supplied a new one will be generated.
   */
  public async removeGiftCard(cardID: string, cartID?: string): Promise<void> {
    if ((typeof window === "undefined")) {
      await ServerCartService.removeGiftCard(cardID, cartID);
    } else {
      await this.client.delete('giftcard', {
        params: {
          cartID: cartID ?? undefined,
          giftCardID: cardID
        }
      });
    }
  }

  /**
   * Adds the promotion by its id.
   * @param promotionName - Promotion name on the given promotion.
   * @param [cartID] - The cart id, if not supplied a new one will be generated.
   * @returns - The new id for the promotion.
   */
  public async addPromotion(
    promotionName: string,
    cartID?: string
  ): Promise<string> {
    if ((typeof window === "undefined")) {
      return ServerCartService.addPromotion(promotionName, cartID);
    }
    const { data } = await this.client.post<unknown, AddResponse>('promotion', {
      cartID: cartID ?? undefined,
      promotionName
    });

    return data.id;
  }

  /**
   * Removes a promotion by the id supplied by the cart api.
   * @param promotionID - Promotion id on the given promotion.
   * @param [cartID] - The cart id associated with the promotion.
   */
  public async removePromotion(
    promotionID: string,
    cartID?: string
  ): Promise<void> {
    if ((typeof window === "undefined")) {
      await ServerCartService.removePromotion(promotionID, cartID);
    } else {
      await this.client.delete('promotion', {
        params: {
          cartID: cartID ?? undefined,
          promotionID
        }
      });
    }
  }
}

export default CartService.withMock(new CartServiceMock(CartService));
