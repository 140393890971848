/** Enumerates possible rejection reasons for a coupon. */
enum CouponRejectionReason {
  // Start with `1` since `0` is falsy and rejection reasons can be `undefined`.
  Expired = 1,
  LimitReached,
  NotFound,
  NotActive,
  NotApplicable,
  AlreadyApplied
}

export default CouponRejectionReason;
