import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import type CouponRejectionReason from '../CouponRejectionReason';

export const { RejectedCouponError } = errorFactory(
  'RejectedCouponError',
  /**
   * This class will add the `couponCode` and `rejectionReason` properties to
   * the error.
   * */
  class extends Error {
    /** @inheritdoc */
    public constructor(
      public couponCode: string,
      public rejectionReason: CouponRejectionReason,
      errorMessage?: string,
      errorOptions?: ErrorOptions
    ) {
      super(
        errorMessage ??
          `Coupon code "${couponCode}" rejected: ${rejectionReason}`,
        errorOptions
      );
    }
  },
  {
    mapsToHTTPError: 'ConflictError'
  }
);

/** A generic error in the event of a rejected coupon. */
export type RejectedCouponError = InstanceTypeOf<typeof RejectedCouponError>;
