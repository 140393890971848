import ShippingMethod from '@/constructs/ShippingMethod';
import Service from '@/services/Service';
import type { IAddress } from '@/services/models/Address';
import type { ICart } from '@/services/models/Cart';
import { MoneyModel } from '@/services/models/Money';
import {
  IShippingMethod,
  ShippingMethodModel
} from '@/services/models/ShippingMethod';
import ServerShippingMethodService from '@/services/serverless/ServerShippingMethodService';
import type { DTO, Nullable } from '@/type-utils';
import LazyValue from '@/utils/LazyValue';
import MemoryCache from '@/utils/MemoryCache';
import { InvalidStateError } from '@/utils/errors';
import { isNullOrEmpty } from '@/utils/null-utils';
import { isPOBoxLine } from '@/utils/string-utils';
import axios from 'axios';
import ConfigurationService from '../ConfigurationService';
import { Brand, EnvironmentService } from '../EnvironmentService';
import I18NService, { Country } from '../I18NService';
import ShippingMethodServiceMock from './ShippingMethodServiceMock';
import type { ShippingMethodNotFoundError } from './errors';
import { CannotShipToPOBoxError } from './errors/CannotShipToPOBoxError';

/** Abstracts operations related to Shipping Methods. */
export class ShippingMethodService extends Service {
  private client = axios.create({
    baseURL: '/api/shipping-methods'
  });

  /**
   * The configured TTL for the isomorphic shipping methods cache.
   * @returns The configured TTL for the isomorphic shipping methods cache.
   */
  private get cacheTTL(): number {
    return ConfigurationService.getConfig('shippingMethods').getSetting(
      'caching.isomorphicCacheTTL'
    ).value;
  }

  private _shippingMethodsCache = new LazyValue(() => {
    return new MemoryCache<ShippingMethodModel>(this.cacheTTL);
  });

  private _shippingMethodUIDsPerBrandLocaleCache = new LazyValue(() => {
    return new MemoryCache<Array<string>>(this.cacheTTL);
  });

  /**
   * Caches any retrieved shipping methods, indexed by their UIDs.
   * @returns The cache described above.
   */
  private get shippingMethodsCache(): MemoryCache<ShippingMethodModel> {
    return this._shippingMethodsCache.value;
  }

  /**
   * Caches the UIDs of the shipping methods applicable per brand-country.
   * @returns The cache described above.
   */
  private get shippingMethodUIDsPerBrandLocaleCache(): MemoryCache<
    Array<string>
  > {
    return this._shippingMethodUIDsPerBrandLocaleCache.value;
  }

  /**
   * Retrieves a specific shipping method by ID.
   *
   * @param uid - The Unique ID of the shipping method to retrieve.
   * @returns A {@link ShippingMethodModel}.
   *
   * @throws A {@link ShippingMethodNotFoundError} if no shipping method is found with
   * the provided ID.
   */
  public async getShippingMethodByUID(
    uid: string
  ): Promise<ShippingMethodModel> {
    // Use cached method if available.
    if (this.shippingMethodsCache.has(uid)) {
      const cachedMethod = this.shippingMethodsCache.get(uid);

      // ensure we return a new instance of the model
      return ShippingMethodModel.from(cachedMethod.toDTO());
    }

    let dto: DTO<IShippingMethod>;

    if ((typeof window === "undefined")) {
      dto = await ServerShippingMethodService.getShippingMethodByUID(uid);
    } else {
      const response = await this.client.get<DTO<IShippingMethod>>(uid);
      dto = response.data;
    }

    const model = ShippingMethodModel.from(dto);
    this.shippingMethodsCache.add(uid, model);
    return model;
  }

  /**
   * Retrieves all shipping methods for the specified brand and country
   * combination.
   *
   * @param brand - Brand to fetch shipping methods for.
   * Defaults to the current site.
   *
   * @param country - Country to fetch shipping methods for.
   * Defaults to the current country.
   *
   * @returns The applicable shipping methods (if any), as an array of
   * {@link ShippingMethodModel} instances.
   */
  public async getShippingMethodsByBrandAndCountry(
    brand: Brand = EnvironmentService.brand,
    country: Country = I18NService.currentLocale.country
  ): Promise<Array<ShippingMethodModel>> {
    if (this.shippingMethodUIDsPerBrandLocaleCache.has(`${brand}-${country}`)) {
      const cachedUIDs = this.shippingMethodUIDsPerBrandLocaleCache.get(
        `${brand}-${country}`
      );

      if (cachedUIDs.every((uid) => this.shippingMethodsCache.has(uid))) {
        return cachedUIDs.map((uid) => this.shippingMethodsCache.get(uid));
      }
    }

    let dtos: Array<DTO<IShippingMethod>>;

    if ((typeof window === "undefined")) {
      dtos =
        await ServerShippingMethodService.getShippingMethodsByBrandAndCountry(
          brand,
          country
        );
    } else {
      const response = await this.client.get<Array<DTO<IShippingMethod>>>('');
      dtos = response.data;
    }

    const uids: Array<string> = [];

    const models = dtos.map((dto) => {
      const model = ShippingMethodModel.from(dto);
      this.shippingMethodsCache.add(model.uid, model);
      uids.push(model.uid);

      return model;
    });

    this.shippingMethodUIDsPerBrandLocaleCache.add(`${brand}-${country}`, uids);
    return models;
  }

  /**
   * Determines the IDs of all the shipping methods applicable for the
   * specified cart.
   *
   * @param cart - The cart to find shipping methods.
   *
   * @returns A string array with all the IDs of the applicable shipping
   * methods.
   *
   * @throws A {@link CannotShipToPOBoxError} if a PO box is set as the ship-to
   * address and shipping to PO boxes is disabled.
   *
   * @throws An {@link InvalidStateError} if a required method is not present in
   * the current brand-country.
   */
  public async getApplicableShippingMethodsForCart(
    cart: ICart
  ): Promise<Array<ShippingMethodModel>> {
    /**
     * **IMPORTANT**
     * Ultimately, the “rules” by which shipping methods are defined will live
     * in a “rules engine” housed in the Conductor administrative panel.
     * However, in the shorter term, prior to launch, we’ll be hard coding in
     * the algorithm by which shipping methods are applied to a cart.
     */

    const availableShippingMethods =
      await this.getShippingMethodsByBrandAndCountry();
    const { shipToAddress } = cart;

    // Pattern-matching switch
    // Good resource on this: https://kyleshevlin.com/pattern-matching
    switch (true) {
      // First, check for exclusionary cases.

      // 1: If the ship-to address belongs to a country different from the
      //    current country, use International Shipping.
      case shipToAddress &&
        !this.isAddressWithinCurrentCountry(shipToAddress): {
        const sfsIntlMethod = availableShippingMethods.find(
          (shippingMethod) => shippingMethod.id === ShippingMethod.SFSIntl
        );

        if (!sfsIntlMethod) {
          throw new InvalidStateError(
            `Shipping method "${ShippingMethod.SFSIntl}" does not exist in` +
              ' the current brand-country, but it is required for ' +
              ' international shipments. Please make sure it is configured' +
              ' in the source (AWS or mock).'
          );
        }

        return [sfsIntlMethod];
      }

      // 2: If the ship-to address is a PO Box,
      //    use United States Postal Service.
      case shipToAddress && this.isAddressPoBox(shipToAddress): {
        const checkoutConfig = ConfigurationService.getConfig('checkout');

        // Get config flags
        const allowShippingToPOBoxes = checkoutConfig.getSetting(
          'allowShippingToPOBoxes'
        ).value;

        if (!allowShippingToPOBoxes) {
          throw new CannotShipToPOBoxError(
            'Cannot get applicable methods for cart: The ship-to address is a' +
              ' PO Box and shipping to PO boxes is currently disabled.'
          );
        }

        const uspsGroundMethod = availableShippingMethods.find(
          (shippingMethod) => shippingMethod.id === ShippingMethod.USPSGround
        );

        if (!uspsGroundMethod) {
          throw new InvalidStateError(
            `Shipping method "${ShippingMethod.USPSGround}" does not exist in` +
              ' the current brand-country, but it is required for PO Box' +
              ' shipments. Please make sure it is configured in the source' +
              ' (AWS or mock).'
          );
        }

        return [uspsGroundMethod];
      }

      default: {
        // Start with shipping methods that are always available.
        const applicableMethodIDs = [ShippingMethod.ST2, ShippingMethod.STO];

        // If all the items in the cart are discounted...
        if (this.cartHasAllItemsDiscounted(cart)) {
          // Cart is excluded from STG, but may use STG2.
          applicableMethodIDs.push(ShippingMethod.STG2);
        } else {
          applicableMethodIDs.push(ShippingMethod.STG);
        }

        // Find the applicable methods
        const applicableMethods = applicableMethodIDs.map((id) => {
          const foundMethod = availableShippingMethods.find(
            (method) => method.id === id
          );

          if (!foundMethod) {
            throw new InvalidStateError(
              `Shipping method "${id}" does not exist in the current` +
                ' brand-country, but it is required as a standard method.' +
                ' Please make sure it is configured in the source (AWS or mock)'
            );
          }

          return foundMethod;
        });

        // Sort by price
        applicableMethods.sort((a, b) =>
          MoneyModel.compare(a.shippingCost, b.shippingCost)
        );

        return applicableMethods;
      }
    }
  }

  /**
   * Determines the best shipping method from the given applicable methods.
   *
   * @param methods - A list of methods to choose from.
   * @returns A {@link ShippingMethodModel} representing the best available method, or
   * `null` if there are no methods in the passed array.
   *
   * @throws An {@link InvalidStateError} if a currency mismatch is detected
   * in shipping costs.
   */
  public getBestShippingMethod(
    methods: Array<ShippingMethodModel>
  ): Nullable<ShippingMethodModel> {
    // Return null if no methods were passed
    if (methods.length === 0) return null;

    // For the time being, until there is further clarity on what constitutes
    // “the best” shipping method (as much is unclear from the production code)
    // this method will choose the least expensive available method.

    const sortedMethods = methods.sort((a, b) => {
      try {
        return MoneyModel.compare(a.shippingCost, b.shippingCost);
      } catch (err) {
        throw new InvalidStateError(
          'Cannot determine best available shipping method:' +
            `Error comparing shipping costs for methods "${a.id}" and "${b.id}".`,
          { cause: err }
        );
      }
    });

    // Return the method with the lowest cost.
    return sortedMethods[0];
  }

  /**
   * Determines if a given address is a PO Box.
   *
   * @param address - Address to test.
   * @returns A `true` value if the address is a PO Box, and `false` otherwise.
   */
  public isAddressPoBox(address: IAddress): boolean {
    const { addressLine1, addressLine2 } = address;

    if (isPOBoxLine(addressLine1)) {
      return true;
    }

    if (!isNullOrEmpty(addressLine2) && isPOBoxLine(addressLine2)) {
      return true;
    }

    return false;
  }

  /**
   * Determines if a given address is within the current country's country.
   *
   * @param address - Address to test.
   *
   * @returns A `true` value if the address is within the country, and `false`
   * otherwise.
   */
  public isAddressWithinCurrentCountry(address: IAddress): boolean {
    const currentLocale = I18NService.currentLocale.country;

    return address.country === currentLocale;
  }

  /**
   * Determines if a given cart has all of its items discounted.
   * Useful for determining if a cart does not apply for free ground shipping.
   *
   * @param cart - Cart to check items for.
   *
   * @returns A `true` value if all of the cart's items are discounted, and
   * `false` otherwise.
   */
  public cartHasAllItemsDiscounted(cart: ICart): boolean {
    // If there are no items, return `false`.
    // This is to avoid having STG2 on first render and then changing to STG.
    if (cart.items.length === 0) {
      return false;
    }

    for (const lineItem of cart.items) {
      // If there is at least one item at full price...
      if (
        MoneyModel.from(lineItem.netTotal).isGreaterThanOrEqualTo(
          lineItem.subtotal
        )
      ) {
        return false;
      }
    }

    return true;
  }
}

export default ShippingMethodService.withMock(
  new ShippingMethodServiceMock(ShippingMethodService)
) as unknown as ShippingMethodService;
